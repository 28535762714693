<template>
  <!-- begin:: Header Topbar -->
  <div class="kt-header__topbar ">
    <!--begin: Notifications -->
    <!--<span class="kt-pulse__ring"></span>-->
    <!-- v-on:update:title="doc.title = $event"-->
    <div
      class="kt-header__topbar-item "
      id="kt_notification_toggle"
      v-if="DisabledNotif === 0 || DisabledNotif === '0'"
    >
      <div class="kt-header__topbar-wrapper" data-toggle="dropdown">
        <span
          class="kt-header__topbar-icon kt-pulse kt-pulse--red"
          v-on:click="ButtonClick()"
        >
          <i class="flaticon2-bell-2" style="font-weight: 50"></i>

          <span v-bind:class="{ 'kt-pulse__ring': Visible }"></span>
        </span>
      </div>
      <!--v-on:click.stop-->
      <div
        class="dropdown-menu dropdown-menu-fit dropdown-menu-xl dropdown-menu-right"
      >
        <form>
          <KTDropdownNotification
            v-on:message-sent="receiveEmit"
            :Clicked="Clicked"
          ></KTDropdownNotification>
        </form>
      </div>
    </div>
    <!--end: Notifications -->

    <!--begin: Language bar -->
    <div
      class="kt-header__topbar-item kt-header__topbar-item--langs poschodoch-language"
    >
      <div
        class="kt-header__topbar-wrapper"
        id="kt_language_toggle"
        data-toggle="dropdown"
      >
        <span class="kt-header__topbar-icon">
          {{ language }}
          <i class="la la-angle-down"></i>
        </span>
      </div>
      <div
        class="dropdown-menu dropdown-menu-fit dropdown-menu-right"
        v-on:click.stop
      >
        <KTDropdownLanguage
          v-on:language-changed="onLanguageChanged"
        ></KTDropdownLanguage>
      </div>
    </div>
    <!--end: Language bar -->

    <!--begin: User Bar -->
    <div
      class="kt-header__topbar-item kt-header__topbar-item--userkt-header__topbar-item kt-header__topbar-item--user poschodoch-user"
    >
      <div
        class="kt-header__topbar-wrapper"
        id="kt_user_toggle"
        data-toggle="dropdown"
      >
        <div class="kt-header__topbar-user">
          <!-- <span class="kt-header__topbar-icon">
            <i class="flaticon2-user-outline-symbol"></i>
          </span> -->
          <img
            alt="Pic"
            style="margin-right: 5px; height: auto"
            src="@/assets/media/users/defaultUser.gif"
          />
          <span class="kt-header__topbar-username kt-hidden-mobile">
            {{ username }}
          </span>
          <i class="la la-angle-down"></i>
        </div>

        <!-- <div class="kt-header__topbar-user">
          <i class="flaticon2-user-outline-symbol"></i>
          <span class="kt-header__topbar-username kt-hidden-mobile">Sean</span>
          <img class="kt-hidden" alt="Pic" src="@/assets/media/users/300_25.jpg" />
          <span class="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold">S</span>
        </div> -->
      </div>

      <div
        class="dropdown-menu dropdown-menu-fit dropdown-menu-xl dropdown-menu-right"
        v-on:click.stop
      >
        <KTDropdownUser></KTDropdownUser>
      </div>
    </div>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<script>
import { mapGetters } from "vuex";
import KTDropdownNotification from "@/views/theme/topbar/DropdownNotification.vue";
import KTDropdownLanguage from "@/views/theme/topbar/DropdownLanguage.vue";
import KTDropdownUser from "@/views/theme/topbar/DropdownUser.vue";
import i18nService from "@/common/i18n.service.js";
import JwtService from "@/common/jwt.service";
import jwtService from "../../../common/jwt.service";

export default {
  //  name: "KTTopbar",
  data() {
    return {
      language: i18nService.getActiveLanguage().toUpperCase(),
      languages: i18nService.languages,
      notif: JwtService.getNotif(),
      username: JwtService.getUsername(),
      Visible: true,
      Clicked: false,
      DisabledNotif: 0,
    };
  },
  components: {
    KTDropdownNotification,
    KTDropdownLanguage,
    KTDropdownUser,
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      var a = jwtService.getDisabledNotif();
      this.DisabledNotif = a;
    },
    receiveEmit(vis) {
      var a = vis;
      this.Visible = a;
      var b = jwtService.getDisabledNotif();
      this.DisabledNotif = b;
    },
    ButtonClick() {
      this.Clicked = !this.Clicked;
    },
    onLanguageChanged() {
      this.language = this.languages
        .find((val) => {
          return val.lang === i18nService.getActiveLanguage();
        })
        .lang.toUpperCase();
    },
  },
  computed: {
    ...mapGetters(["isNotification"]),
  },
};
</script>
