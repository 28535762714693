<template>
  <div>
    <DeactivateUnit></DeactivateUnit>
    <!--begin: Head -->
    <div
      class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <div class="kt-user-card__avatar">
        <a href="/AccountProfile" v-if="visible">
          <img
            v-if="photo != null && photo != ''"
            alt="Pic"
            v-bind:src="'data:image/png;base64,' + photo"
          />
          <img v-else alt="Pic" src="@/assets/media/users/default.jpg" />
        </a>
        <img v-if="!visible" alt="Pic" src="@/assets/media/users/default.jpg" />
        <!--use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) -->
        <!-- <span
          class="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success"
          >S
        </span> -->
      </div>
      <a href="/AccountProfile" v-if="visible">
        <div class="kt-user-card__name">{{ email }}</div>
      </a>
      <div class="kt-user-card__name" v-if="!visible">{{ email }}</div>
      <!-- <div class="kt-user-card__badge">
        <span class="btn btn-success btn-sm btn-bold btn-font-md"
          ># notifikácii</span
        >
      </div> -->
    </div>
    <!--end: Head -->
    <!--begin: Navigation -->
    <div class="kt-notification">
      <a href="/AccountProfile" class="kt-notification__item" v-if="visible">
        <div class="kt-notification__item-icon">
          <i class="flaticon2-calendar-3 kt-font-success"></i>
        </div>
        <div class="kt-notification__item-details">
          <div class="kt-notification__item-title kt-font-bold">
            {{ $i18n.t("ACCOUNTPROFILE.DROPDOWNUSER.PROFILE") }}
          </div>
        </div>
      </a>
      <a href="/UnitsNotif" class="kt-notification__item" v-if="visibleNotif">
        <!--<a href="" class="kt-notification__item" v-if="visible"> -->
        <div class="kt-notification__item-icon">
          <i class="flaticon2-mail kt-font-warning"></i>
        </div>
        <div class="kt-notification__item-details">
          <div class="kt-notification__item-title kt-font-bold">
            {{ $i18n.t("ACCOUNTPROFILE.DROPDOWNUSER.NOTIFICATIONSETTINGS") }}
          </div>
          <div class="kt-notification__item-time">
            {{ $i18n.t("ACCOUNTPROFILE.DROPDOWNUSER.INPROGRESS") }}
          </div>
        </div>
      </a>
      <router-link
        class="kt-notification__item"
        :to="{ name: 'registrationCode', params: { haveAccount: true } }"
      >
        <div class="kt-notification__item-icon">
          <i class="flaticon2-hospital kt-font-danger"></i>
        </div>
        <div class="kt-notification__item-details">
          <div class="kt-notification__item-title kt-font-bold">
            {{ $i18n.t("ACCOUNTPROFILE.DROPDOWNUSER.ADDSPACE") }}
          </div>
        </div>
      </router-link>
      <a
        href="javascript:void(0)"
        v-b-modal.modal-deactivate-unit-list
        class="kt-notification__item"
        v-if="visible"
      >
        <!--<a href="/UnitList" class="kt-notification__item">-->
        <div class="kt-notification__item-icon">
          <i class="flaticon2-cross kt-font-danger"></i>
        </div>
        <div class="kt-notification__item-details">
          <div class="kt-notification__item-title kt-font-bold">
            {{ $i18n.t("ACCOUNTPROFILE.DROPDOWNUSER.CANCELSPACE") }}
          </div>
          <!-- <div class="kt-notification__item-time"></div> -->
        </div>
      </a>
      <!-- <a href="" class="kt-notification__item">
        <div class="kt-notification__item-icon">
          <i class="flaticon2-hospital kt-font-danger"></i>
        </div>
        <div class="kt-notification__item-details">
          <div class="kt-notification__item-title kt-font-bold">
            {{ $i18n.t("ACCOUNTPROFILE.DROPDOWNUSER.ADDSPACE") }}
          </div>
          <div class="kt-notification__item-time"></div> 
        </div>
      </a> -->
      <div class="kt-notification__custom kt-space-between">
        <b-button
          v-on:click="onLogout()"
          size="sm"
          class="btn-bold poschodoch-logout-btn"
          variant="label"
        >
          {{ $t("AUTH.GENERAL.SIGNOUT_BUTTON") }}
        </b-button>
      </div>
    </div>
    <!--end: Navigation -->
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { LOGOUT } from "@/store/auth.module";
import JwtService from "@/common/jwt.service";
import DeactivateUnit from "@/views/pages/auth/DeactivateUnit.vue";
//import { mapGetters } from "vuex";

export default {
  name: "KTDropdownUser",
  data() {
    return {
      email: JwtService.getUsername(),
      photo: null,
      visible: false,
      DisabledNotif: 0,
      isA: false,
      visibleNotif: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  components: {
    DeactivateUnit,
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => {
          this.$router.push({ name: "login" });
        })
        .catch((err) => console.log(err));
    },
    fetchData() {
      ApiService.setHeader();
      ApiService.get("Account", "Profile").then(({ data }) => {
        this.photo = data.Photo;
        this.DisabledNotif = data.DisabledNotif;
        /*if (data.DisabledNotif === 0){
          JwtService.setDisabledNotif(0);
          this.visibleNotif = true;
        }else{
          JwtService.setDisabledNotif(1);
          this.visibleNotif = false;
        }*/ let a = JwtService.getDisabledNotif();
        if (!a) {
          JwtService.setDisabledNotif(0);
        } else {
          if (a === 1 || a === "1") {
            this.visibleNotif = false;
          } else {
            this.visibleNotif = true;
          }
        }
        this.visible = data.Visible === 1 ? true : false;
      });
    },
  },
  computed: {
    /*...mapGetters([
      "isAuthenticated",      
    ]),*/
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/misc/bg-1.jpg";
    },
  },
};
</script>
