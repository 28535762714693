<template>
  <div>
    <!--:style="{ backgroundImage: `url(${backgroundImage})` }"-->
    <div
      class="kt-head kt-head--space-smm kt-head--skin-dark kt-head--fit-x kt-head--fit-b kt-bg-brand"
    >
      <h3 class="kt-head__title">
        {{ $i18n.t("NOTIFICATIONS.NOTIF") }}
        <span
          class="ml-2 btn btn-success btn-sm btn-bold btn-font-md mb-1"
          v-on:click="showNotifications()"
        >
          {{ NotifCount }}
        </span>
      </h3>
    </div>
    <!-- <ul
        class="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-success kt-notification-item-padding-x"
        role="tablist"
      >
        <li class="nav-item">
          <a
            v-on:click="setActiveTab"
            data-tab="0"
            class="nav-link active"
            data-toggle="tab"
            href="#"
            role="tab"
          >
            Alerts
          </a>
        </li>
        <li class="nav-item">
          <a
            v-on:click="setActiveTab"
            data-tab="1"
            class="nav-link"
            data-toggle="tab"
            href="#"
            role="tab"
          >
            Events
          </a>
        </li>
        <li class="nav-item">
          <a
            v-on:click="setActiveTab"
            data-tab="2"
            class="nav-link"
            data-toggle="tab"
            href="#"
            role="tab"
          >
            Logs
          </a>
        </li>
      </ul>-->
    <b-tabs class="kt-hide-tabs" v-model="tabIndex">
      <b-tab active>
        <perfect-scrollbar
          class="kt-notification kt-margin-t-10 kt-margin-b-10 "
          style="max-height: 40vh; position: relative;"
        >
          <div v-for="(Notification, index) in notificationData" :key="index">
            <a
              href="#"
              v-on:click="
                showNotifications(
                  Notification.NotifId,
                  Notification.Code,
                  Notification,
                )
              "
              class="kt-notification__item"
            >
              <div class="kt-notification__item-icon">
                <i class="flaticon2-line-chart kt-font-success"></i>
              </div>
              <div class="kt-notification__item-details">
                <div
                  class="kt-notification__item-title"
                  v-bind:class="{ 'font-weight-bold': !Notification.Read }"
                >
                  {{ Notification.Description }}
                </div>
                <div class="kt-notification__item-time">
                  {{
                    getFormatService().formatDateTimeToLocal(
                      Notification.CreationDate,
                    )
                  }}
                </div>
              </div>
            </a>
          </div>
        </perfect-scrollbar>
      </b-tab>

      <!--          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon2-line-chart kt-font-success"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                New order has been received
              </div>
              <div class="kt-notification__item-time">2 hrs ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon2-box-1 kt-font-brand"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                New customer is registered
              </div>
              <div class="kt-notification__item-time">3 hrs ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon2-chart2 kt-font-danger"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                Application has been approved
              </div>
              <div class="kt-notification__item-time">3 hrs ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon2-image-file kt-font-warning"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                New file has been uploaded
              </div>
              <div class="kt-notification__item-time">5 hrs ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon2-bar-chart kt-font-info"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                New user feedback received
              </div>
              <div class="kt-notification__item-time">8 hrs ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon2-pie-chart-2 kt-font-success"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                System reboot has been successfully completed
              </div>
              <div class="kt-notification__item-time">12 hrs ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon2-favourite kt-font-danger"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                New order has been placed
              </div>
              <div class="kt-notification__item-time">15 hrs ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item kt-notification__item--read">
            <div class="kt-notification__item-icon">
              <i class="flaticon2-safe kt-font-primary"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                Company meeting canceled
              </div>
              <div class="kt-notification__item-time">19 hrs ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon2-psd kt-font-success"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                New report has been received
              </div>
              <div class="kt-notification__item-time">23 hrs ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon-download-1 kt-font-danger"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                Finance report has been generated
              </div>
              <div class="kt-notification__item-time">25 hrs ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon-security kt-font-warning"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                New customer comment recieved
              </div>
              <div class="kt-notification__item-time">2 days ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon2-pie-chart kt-font-success"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                New customer is registered
              </div>
              <div class="kt-notification__item-time">3 days ago</div>
            </div>
          </a>
        </perfect-scrollbar>
      </b-tab>

      <b-tab>
        <perfect-scrollbar
          class="kt-notification kt-margin-t-10 kt-margin-b-10"
          style="max-height: 40vh; position: relative"
        >
          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon2-psd kt-font-success"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                New report has been received
              </div>
              <div class="kt-notification__item-time">23 hrs ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon-download-1 kt-font-danger"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                Finance report has been generated
              </div>
              <div class="kt-notification__item-time">25 hrs ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon2-line-chart kt-font-success"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                New order has been received
              </div>
              <div class="kt-notification__item-time">2 hrs ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon2-box-1 kt-font-brand"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                New customer is registered
              </div>
              <div class="kt-notification__item-time">3 hrs ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon2-chart2 kt-font-danger"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                Application has been approved
              </div>
              <div class="kt-notification__item-time">3 hrs ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon2-image-file kt-font-warning"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                New file has been uploaded
              </div>
              <div class="kt-notification__item-time">5 hrs ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon2-bar-chart kt-font-info"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                New user feedback received
              </div>
              <div class="kt-notification__item-time">8 hrs ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon2-pie-chart-2 kt-font-success"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                System reboot has been successfully completed
              </div>
              <div class="kt-notification__item-time">12 hrs ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon2-favourite kt-font-brand"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                New order has been placed
              </div>
              <div class="kt-notification__item-time">15 hrs ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item kt-notification__item--read">
            <div class="kt-notification__item-icon">
              <i class="flaticon2-safe kt-font-primary"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                Company meeting canceled
              </div>
              <div class="kt-notification__item-time">19 hrs ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon2-psd kt-font-success"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                New report has been received
              </div>
              <div class="kt-notification__item-time">23 hrs ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon-download-1 kt-font-danger"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                Finance report has been generated
              </div>
              <div class="kt-notification__item-time">25 hrs ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon-security kt-font-warning"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                New customer comment recieved
              </div>
              <div class="kt-notification__item-time">2 days ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon2-pie-chart kt-font-success"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                New customer is registered
              </div>
              <div class="kt-notification__item-time">3 days ago</div>
            </div>
          </a>
        </perfect-scrollbar>
      </b-tab>

      <b-tab>
        <perfect-scrollbar
          class="kt-notification kt-margin-t-10 kt-margin-b-10"
          style="max-height: 40vh; position: relative"
        >
          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon2-psd kt-font-success"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                New report has been received
              </div>
              <div class="kt-notification__item-time">23 hrs ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon-download-1 kt-font-danger"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                Finance report has been generated
              </div>
              <div class="kt-notification__item-time">25 hrs ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon2-line-chart kt-font-success"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                New order has been received
              </div>
              <div class="kt-notification__item-time">2 hrs ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon2-box-1 kt-font-brand"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                New customer is registered
              </div>
              <div class="kt-notification__item-time">3 hrs ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon2-chart2 kt-font-danger"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                Application has been approved
              </div>
              <div class="kt-notification__item-time">3 hrs ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon2-image-file kt-font-warning"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                New file has been uploaded
              </div>
              <div class="kt-notification__item-time">5 hrs ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon2-bar-chart kt-font-info"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                New user feedback received
              </div>
              <div class="kt-notification__item-time">8 hrs ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon2-pie-chart-2 kt-font-success"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                System reboot has been successfully completed
              </div>
              <div class="kt-notification__item-time">12 hrs ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon2-favourite kt-font-brand"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                New order has been placed
              </div>
              <div class="kt-notification__item-time">15 hrs ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item kt-notification__item--read">
            <div class="kt-notification__item-icon">
              <i class="flaticon2-safe kt-font-primary"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                Company meeting canceled
              </div>
              <div class="kt-notification__item-time">19 hrs ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon2-psd kt-font-success"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                New report has been received
              </div>
              <div class="kt-notification__item-time">23 hrs ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon-download-1 kt-font-danger"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                Finance report has been generated
              </div>
              <div class="kt-notification__item-time">25 hrs ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon-security kt-font-warning"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                New customer comment recieved
              </div>
              <div class="kt-notification__item-time">2 days ago</div>
            </div>
          </a>
          <a href="#" class="kt-notification__item">
            <div class="kt-notification__item-icon">
              <i class="flaticon2-pie-chart kt-font-success"></i>
            </div>
            <div class="kt-notification__item-details">
              <div class="kt-notification__item-title">
                New customer is registered
              </div>
              <div class="kt-notification__item-time">3 days ago</div>
            </div>
          </a>
        </perfect-scrollbar>
      </b-tab> -->
    </b-tabs>
  </div>
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.kt-hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";
import FormatService from "@/common/format.service";
import { mapGetters } from "vuex";
import MenuService from "@/common/menu.service";
import jwtService from "../../../common/jwt.service";

export default {
  name: "KTDropdownNotification",
  data() {
    return {
      menuMappings: [],
      notificationData: [],
      tabIndex: 0,
      NotifCount: null,
    };
  },
  mounted() {
    this.fetchData();
  },
  props: ["Clicked"],
  watch: {
    Clicked: function() {
      //if (newval) {
      this.fetchData();
      //}
    },
  },
  methods: {
    showNotifications(index, kod, item) {
      //let a = window.location;
      this.$emit("message-sent", false);
      if (item) {
        let postContent = {
          notificationId: index,
          OtherNotif: item.OtherNotif,
          OtherUsers: item.OtherUsers,
        };

        ApiService.setHeader();
        ApiService.post("Dashboard/NotificationRead", postContent).catch(
          (error) => {
            if (error.response.data.error) {
              this.errors.push(error.response.data.error);
            } else {
              this.errors.push(this.$i18n.t("AUTHJS.SOMETHINGWRONG") + error);
            }
          },
        );
      }
      if (
        kod == "DOC_OBJ" &&
        !window.location.href.includes("/documentListObject")
      ) {
        let parameters = MenuService.getMenuID("DocumentListObject");
        if (parameters != 0) {
          this.$router.push({
            name: "domus-documents-object",
            query: { menuId: parameters },
          });
        }
      } else if (
        kod == "SYS_EVE" &&
        !window.location.href.includes("/events")
      ) {
        let parameters = MenuService.getMenuID("Events");
        if (parameters != 0) {
          this.$router.push({
            name: "domus-events",
            query: { menuId: parameters },
          });
        }
      } else if (
        (kod == "DOC_ZAS" || kod == "DOC_UZI") &&
        !window.location.href.includes("/documentListUser")
      ) {
        let parameters = MenuService.getMenuID("DocumentListUser");
        if (parameters != 0) {
          this.$router.push({
            name: "domus-documents-user",
            query: { menuId: parameters },
          });
        }
      } else if (
        (kod == "MER_ST" ||
          kod == "MER_NS" ||
          kod == "MER_NS_PER" ||
          kod == "MER_0S" ||
          kod == "MER_NOSIG") &&
        !window.location.href.includes("/dailyConsumption")
      ) {
        let parameters = MenuService.getMenuID("DailyConsumption");
        if (parameters != 0) {
          this.$router.push({
            name: "domus-daily-consumption",
            query: { menuId: parameters },
          });
        }
      } else if (
        kod == "PLA_NED" &&
        !window.location.href.includes("/account")
      ) {
        let parameters = MenuService.getMenuID("account");
        if (parameters != 0) {
          this.$router.push({
            name: "domus-account",
            query: { menuId: parameters },
          });
        }
      } else if (!window.location.href.includes("/notifications") && !kod) {
        this.$router.push({
          name: "domus-notifications",
          query: { notifId: index },
        });
      }
    },
    getFormatService() {
      return FormatService;
    },
    getDetailParameters(menuCode) {
      let parameters = "";
      parameters += this.menuMappings[menuCode];
      return parameters;
    },
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");
    },
    fetchData() {
      /* if (this.getDetailParameters("summary") != -103) {
      let parameters = "?preview=0";
      ApiService.setHeader();
      ApiService.get("Dashboard", "Menu" + parameters)
        .then(({ data }) => {
          // preview menu
          for (let i = 0; i < data.length; i++) {
            this.menuMappings[data[i].MenuCode] = data[i].MenuId;
          }
        })
        .catch(({ response }) => {
          if (response.data.error)
            this.errors.push(response.data.error + " (Menu)");
          else
            this.errors.push(
              this.$i18n.t("AUTHJS.SOMETHINGWRONG") + " (Menu)",
            );
      });
    }*/
      //this.items = null;
      /*let parameters =
        "?type=0";*/
      /*if (!JwtService.getNotif()) {
        this.$emit("message-sent", false);
      } else {*/
      //if (this.eventId){
      //TODO NOTIF
      /*  JwtService.setDisabledNotif(1);
          this.$emit("message-sent", false);
          JwtService.setNotif(true);*/
      if (!jwtService.getUnit()) {
        return;
      }
      ApiService.setHeader();
      ApiService.get("Dashboard", "GetNotifications").then(({ data }) => {
        //this.transformData(data.CostDetail);
        if (data.Disabled == 0) {
          let data1 = data.Notifications.slice(0, 10);
          this.notificationData = data1;
          JwtService.setDisabledNotif(0);
          JwtService.setNotif(true);

          //let a = JwtService.getNotif();

          //this.$emit('update:VisibleClassBell', true);
          this.NotifCount = data.CountRead;
          if (data.CountRead > 0) {
            this.$emit("message-sent", true);
          } else {
            this.$emit("message-sent", false);
          }
        } else {
          JwtService.setDisabledNotif(1);
          this.$emit("message-sent", false);
          JwtService.setNotif(true);
        }
      });
      /*   .catch((error) => {
            if (error.response.data.error) {
              // this.errors.push(error.response.data.error);
            } else {
              //this.errors.push(
              //"Niečo sa pokazilo. Pracujeme na tom..."+error );
            }
          }); */
      /* }
      JwtService.setNotif(true);*/
    },
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/misc/bg-1.jpg";
    },
  },
};
</script>
